import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getProductVisibilityRoles } from '@apple/features/auth';
import { getProductLines } from '@apple/features/product/api/product-lines';
import type { ProductKey } from '@apple/features/product/models/product';

import { getKeywords } from '../api/keywords';
import {
	getEolReplacementRecords,
	getInventory,
	getPrices,
	getProduct,
	getProductAudits,
	getUnassignedSkus,
} from '../api/management';

export const productQueryKeys = {
	product: (itemId: ProductKey) => ['product', itemId],
	audit: (itemId: ProductKey) => ['product', itemId, 'audit'],
	inventory: (itemId: ProductKey) => ['product', itemId, 'inventory'],
	prices: (itemId: ProductKey) => ['product', itemId, 'prices'],
	eolReplacements: (itemId: ProductKey) => ['product', itemId, 'eol-replacements'],
	keywords: ['products', 'keywords'],
	visibility: ['products', 'visibility'],
	productLines: ['products', 'lines'],
	unassignedSkus: ['products', 'unassigned-skus'],
} as const;

export const productQueryOptions = {
	product: (itemId: ProductKey) =>
		queryOptions({
			queryKey: productQueryKeys.product(itemId),
			queryFn: () => getProduct(itemId),
			staleTime: 5 * 60 * 1000, // 5 minutes
		}),
	audit: (itemId: ProductKey) =>
		queryOptions({
			queryKey: productQueryKeys.audit(itemId),
			queryFn: () => getProductAudits(itemId),
			placeholderData: keepPreviousData,
			staleTime: 5 * 60 * 1000, // 5 minutes
			initialData: [],
		}),
	inventory: (itemId: ProductKey, enabled: boolean) =>
		queryOptions({
			queryKey: productQueryKeys.inventory(itemId),
			queryFn: () => getInventory(itemId),
			staleTime: 15 * 60 * 1000,
			enabled,
		}),
	prices: (itemId: ProductKey, enabled: boolean) =>
		queryOptions({
			queryKey: productQueryKeys.prices(itemId),
			queryFn: () => getPrices(itemId),
			staleTime: 15 * 60 * 1000,
			enabled,
		}),
	eolReplacements: (itemId: ProductKey, enabled: boolean) =>
		queryOptions({
			queryKey: productQueryKeys.eolReplacements(itemId),
			queryFn: () => getEolReplacementRecords(itemId),
			staleTime: 15 * 60 * 1000,
			enabled,
		}),
	keywords: queryOptions({
		queryKey: productQueryKeys.keywords,
		queryFn: getKeywords,
		staleTime: 5 * 60 * 1000, // 5 minutes
	}),
	visibility: queryOptions({
		queryKey: productQueryKeys.visibility,
		queryFn: getProductVisibilityRoles,
		staleTime: Infinity,
	}),
	productLines: queryOptions({
		queryKey: productQueryKeys.productLines,
		queryFn: getProductLines,
		staleTime: Infinity,
	}),
	unassignedSkus: (enabled: boolean = true) =>
		queryOptions({
			queryKey: productQueryKeys.unassignedSkus,
			queryFn: () => getUnassignedSkus(),
			staleTime: 15 * 60 * 1000,
			enabled,
		}),
};
